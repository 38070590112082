/* Layout */
.policy-updates-page {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduce gap between sections */
  padding: 10px; /* Reduce padding */
}

.filters-and-search {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px; /* Reduce space between the search section and the content */
}

/* Proper alignment for search box and buttons */
.search-box {
  flex: 1; /* Allows search box to expand */
}

.search-box input {
  width: 100%;
}

.add-button-container {
  display: flex;
  gap: 10px;
}

.content-container {
  display: flex;
  gap: 20px;
  margin-top: 10px; /* Reduce margin between sections */
}

.left-content {
  flex: 2;
}

.right-content {
  flex: 1;
}

/* Updates Section */
.updates-section {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
}

.updates-section h3 {
  color: #137A08;
  font-size: 16px;
  margin-bottom: 15px;
}

.update-item {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #8F8F8F;
  padding-bottom: 15px;
  min-height: 150px; /* Set a minimum height for consistency */
}

.update-item .update-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.update-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.update-content h4 {
  color: #036f36;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}

.update-content p {
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}

.update-content a {
  color: blue;
  text-decoration: underline;
}

.update-meta {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #555;
  align-items: center; /* Ensure alignment */
}

.update-meta .meta-left {
  display: flex;
  align-items: center;
}

.update-meta .profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.meta-details {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
  font-size: 12px;
  color: #000000;
}

.timestamp {
  color: #000000;
  font-size: 10px;
  font-weight: 400;
}

.action-buttons {
  display: flex;
  gap: 15px;
  align-items: center; /* Ensure alignment with meta-left */
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.edit-button {
  color: #036f36;
}

.delete-button {
  color: #f44336;
  /* background-color: #ffffff; */
}

/* .delete-button:hover {
  background-color: #d9534f;
} */

/* Upcoming Events Section */
.events-container {
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  padding: 15px;
}

.events-container h3 {
  color: #036f36;
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #8F8F8F;
  margin-bottom: 10px;
  position: relative;
}

.event-card:last-child {
  border-bottom: none;
}

.event-date {
  background-color: #EAF9F0;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: center;
  margin-right: 15px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-date span {
  display: block;
  font-size: 10px;
  color: #036f36;
}

.event-date .date-number {
  font-size: 24px;
  font-weight: 700;
  color: #036f36;
  margin-top: 5px;
}

.event-date .date-year {
  font-size: 10px;
  color: #036f36;
  margin-top: 5px;
}

.event-details {
  flex-grow: 1;
  margin-right: 30px;
}

.event-details h5 {
  color: #036f36;
  font-size: 14px;
  margin-bottom: 5px;
}

.event-details p {
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.event-details p span {
  margin-right: 8px;
  font-size: 12px;
}

.event-details .event-type {
  background-color: #dcdcdc;
  padding: 5px 8px;
  border-radius: 12px;
  font-size: 10px;
  display: inline-block;
}

/* Aligning event actions to be more consistent */
/* Aligning event actions to be consistent */
.event-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Aligns both edit and delete buttons */
  gap: 10px; /* Add gap between the edit and delete buttons */
  position: absolute;
  right: 0; /* Align buttons to the far right */
  top: 50%;
  transform: translateY(-50%); /* Center both buttons vertically */
}

.event-actions .edit-button,
.event-actions .delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
  transition: background-color 0.3s ease;
}

.event-actions .edit-button {
  color: #036f36;
}

.event-actions .delete-button {
  color: #f44336;
  /* background-color: #ffffff; */
}

/* .event-actions .delete-button:hover {
  background-color: #d9534f;
} */


/* Responsive Design */
@media (max-width: 768px) {
  .event-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .event-date {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: left;
  }

  .event-actions {
    position: static;
    transform: none;
    margin-top: 10px;
    align-self: flex-end;
  }

  .event-details h5 {
    font-size: 12px;
  }

  .event-details p,
  .event-details span {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .event-date {
    flex-direction: column;
    text-align: center;
  }

  .event-details {
    font-size: 12px;
  }

  .edit-button,
  .delete-button {
    font-size: 12px;
  }
}


/* Modal styling to match password form design */
.custom-modal {
  max-width: 600px;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.custom-modal .modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  font-family: 'Poppins', sans-serif;
}

.custom-modal .modal-header {
  background-color: white;
  color: #036F36;
  border-bottom: none;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  padding: 1rem;
  /* border-radius: 12px 12px 0 0; */
}

.modal-subtext {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
  color: #555;
  font-size: 12px;
  font-style: italic;
}

.custom-modal .modal-body {
  padding: 1.5rem 0;
}

.custom-modal .modal-body label {
  font-size: 1rem;
  font-weight: 600;
  color: #036F36;
  margin-bottom: 0.5rem;
  display: block;
}

.custom-modal .modal-body input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 1rem;
}

.custom-modal .modal-body input:focus {
  outline: none;
  border-color: #036F36;
  box-shadow: 0 0 4px rgba(3, 111, 54, 0.3);
}

.custom-modal .modal-footer {
  display: flex;
  justify-content: space-between;
  border-top: none;
  padding: 1rem;
}

.custom-modal .modal-footer button {
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

/* Primary button */
.custom-modal .modal-footer .btn-primary {
  background-color: #036F36;
  color: #FFFDB8;
  border: none;
}

.custom-modal .modal-footer .btn-primary:hover {
  background-color: #028a31;
}

/* Secondary button */
.custom-modal .modal-footer .btn-secondary {
  background-color: #dee2e6;
  color: #4d4d4d;
}

.custom-modal .modal-footer .btn-secondary:hover {
  background-color: #c4c4c4;
}

