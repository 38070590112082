.count-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20rem;
    margin: auto;
    padding: 1rem;
}

.count-info {
    display: flex;
    align-items: center;
}
  
.icon-container {
    background-color: #F2EADF;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
}
  
.icon-container i {
    font-size: 1.5rem;
    color: #4b5563;
}
  
.title {
    font-size: 1.25rem;
    font-weight: 300;
}
  
.divider {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    align-self: stretch;
    margin: 0 1rem;
}
  
.count {
    font-size: 3rem;
    font-weight: 500;
    color: #036F36;
}
