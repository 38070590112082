/* General Table Styles */
.custom-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #eaeaea; /* Bottom border for the entire table */
  }
  
  .custom-table th, .custom-table td {
    border: none; /* Remove all internal borders */
    padding: 10px 15px;
    text-align: left;
  }
  
  .custom-table th {
    font-weight: bold;
    color: #333;
  }
  
  .custom-row td {
    border-bottom: 1px solid #eaeaea; /* Only a bottom border for rows */
    background-color: transparent !important; /* Make background transparent */
  }
  
  .custom-row td:last-child {
    text-align: center; /* Center the 'Actions' column content */
  }
  
  /* Header with Rounded Corners */
  .custom-header {
    background-color: #f8f9fa; /* Light background for the header */
    border-top-left-radius: 8px; /* Round top left corner */
    border-top-right-radius: 8px; /* Round top right corner */
    border-bottom: 2px solid #eaeaea; /* A solid bottom border for the header */
  }
  
  .custom-header th:first-child {
    border-top-left-radius: 8px; /* Apply to the first header cell */
  }
  
  .custom-header th:last-child {
    border-top-right-radius: 8px; /* Apply to the last header cell */
  }
  
  /* Row Hover Effects */
  .custom-row:hover {
    background-color: #f1f1f1; /* Slight hover effect on rows */
  }
  
  /* Hide left and right borders */
  .custom-table th, .custom-table td {
    border-left: none;
    border-right: none;
  }
  
  /* Actions Icons */
  .custom-row td .mdi-pencil {
    color: #28a745; /* Green edit icon */
  }
  
  .custom-row td .mdi-delete {
    color: #dc3545; /* Red delete icon */
  }
  