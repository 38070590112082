/* Sidebar styles */
.sidebar {
  position: fixed;
  right: -400px;
  top: 0;
  width: 400px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #ddd;
  transition: right 0.3s ease; /* Slide-in transition */
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer;
}

.sidebar-body {
  padding: 30px;
  /* border: 2px solid #137a08; */
  /* border-radius: 12px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  height: calc(100% - 40px); /* Adjust height to account for header space */
  overflow-y: auto;  /* Allow vertical scrolling */
}

/* Align form contents */
.form-group {
  margin-bottom: 25px; /* Increased spacing between form elements */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;  /* Ensure fields span full width */
}

.label {
  font-size: 1rem;
  font-weight: bold;
  color: #137a08;
  margin-bottom: 8px;
}

.required {
  color: red;
}

/* Password input wrapper */
.password-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

/* Keep text input fields the same size */
.input-field {
  width: 100%; /* Full width to match the button */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  border-radius: 0px; /* Remove rounded borders */
  border: 1px solid #dcdcdc;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 15px; /* Spacing at the bottom of the input fields */
}

.input-field:focus {
  outline: none;
  border: 1px solid #137a08;
}

/* Center the eye icon inside input */
.toggle-visibility {
  position: absolute;
  right: 10px;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust to the middle of the input */
  display: flex;
  align-items: center;
  height: 100%; /* Ensure the icon takes the full height of the input field */
  justify-content: center; /* Center the icon */
  cursor: pointer;
}

/* Password strength bar */
.password-strength-bar-wrapper {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* Remove border and align description */
.description {
  color: #747272;
  font-size: 0.9rem;
  margin-top: -5px; /* Remove extra space above the description text */
  margin-bottom: 10px; /* Space between description and next element */
  border: none !important; /* Ensure there is absolutely no border */
  padding: 0; /* Remove padding */
  background: none; /* Remove background */
}

/* Align the update button */
.update-button {
  width: 100%; /* Ensure the button also takes full width */
  box-sizing: border-box;
  background-color: #137a08;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 30px;
  padding: 12px;
  color: white;
  border: none;
  text-align: center;  /* Ensure text is centered */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px; /* Increase space between the button and the fields above */
  transition: background-color 0.3s ease;
}

.update-button:hover {
  background-color: #0e5c06;
}

.update-button span {
  margin-left: 10px;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 1em; /* Increase padding as desired */
  border: 1px solid red; /* Optional: add a border for emphasis */
  background-color: #ffe6e6; /* Optional: light background for better visibility */
  border-radius: 5px; /* Optional: rounded corners */
}
