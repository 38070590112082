.hide {
    display: none;
}
    
.div-1:hover + .hide-parent .hide {
    display: block;
    box-shadow: 0px 0px 1px #585a5f, 0px 0px 1.5px #585a5f;
    width: 339px;
    padding: 3%;
    margin-top: 15px;
    position: absolute;
    bottom: 100px;
    background-color: #FFFFFFFF;
    border-radius: 4px;
    z-index:1;
}