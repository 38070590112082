.cards-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0;
  }
  
  .custom-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 15px;
    background-color: transparent; /* Remove background to blend */
    border: none; /* Remove border */
    box-shadow: none; /* Remove shadow */
    height: 80px; /* Keep height consistent */
  }
  
  .card-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .card-icon {
    background-color: #f0ebe1;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    /* font-size: 16px;
    color: #000000;
    font-weight: 100;
    margin: 0.4rem 0; */
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .card-divider {
    width: 1px;
    height: 45px;
    background-color: #e0e0e0;
    margin: 0 15px;
  }
  
  .card-count {
    font-size: 24px;
    font-weight: bold;
    color: #036F36;
  }
  