/* General Layout */
.add-update-event-button {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

/* Popup Messages */
.popup-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  z-index: 1000; /* Ensure the message appears above other elements */
}

.popup-message.success {
  background-color: #28a745; /* Green for success */
}

.popup-message.error {
  background-color: #dc3545; /* Red for error */
}

/* Show the popup message */
.popup-message.show {
  opacity: 1;
  visibility: visible;
}

/* Dropdown Styles */
.dropdown-toggle {
  background-color: #137A08 !important;
  border-radius: 8px !important;
  color: white !important;
  padding: 10px 20px !important;
}

.dropdown-menu {
  border-radius: 8px;
  padding: 5px;
}

.policy-updates-dropdown .dropdown-item:hover {
  background-color: #FFF71F;
  /* Hover color */
  color: #4D4D4D;
  /* Ensure the text color remains the same */
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

/* Modal Styles */
.modal-header {
  /* background-color: #137A08; */
  /* color: white; */
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 15px; */
  font-size: large;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.modal-footer .btn-primary {
  background-color: #137A08;
  border-color: #137A08;
  transition: background-color 0.3s ease;
}

.modal-footer .btn-primary:hover {
  background-color: #0e5a07;
}

.modal-footer .btn-secondary {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  color: black;
}

/* Form Elements */
.modal-body label {
  font-weight: bold;
  margin-top: 15px;
  display: block;
}

.modal-body .input-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.modal-body .input-field[type="file"] {
  padding: 0;
}

.form-group {
  margin-top: 20px;
}

.common-button {
  display: flex;
  align-items: center;
  background-color: rgb(3, 111, 54); /* green background */
  color: #FFFDB8; /* light yellow text */
  margin: 15px 10px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px; /* rounded corners */
  padding: 6px 12px; /* inner padding */
  border: none; /* no border */
  gap: 0 4px; /* gap between elements in the button */
  cursor: pointer; /* hand cursor on hover */
}

.custom-modal {
  max-width: 600px;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.custom-modal .modal-header {
  background-color: #137A08;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  text-align: center;
}

.modal-subtext {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
  color: #555;
  font-size: 12px;
  font-style: italic;
}

.custom-modal .modal-body {
  padding: 15px;
}

.custom-modal .modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.custom-modal .modal-footer .btn-primary {
  background-color: #036F36;
  color: #FFFDB8;
  border-radius: 8px;
  padding: 8px 15px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: 48%;
}

.custom-modal .modal-footer .btn-primary:hover {
  background-color: #028a31;
}

.custom-modal .modal-footer .btn-secondary {
  background-color: #c4c4c4;
  color: black;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  width: 48%;
}

.modal-body .input-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.modal-body label {
  margin-top: 15px;
  display: block;
  font-weight: bold;
}

.required {
  color: red;
}


/* Responsive Design */
@media (max-width: 768px) {
  .common-button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .modal-header,
  .modal-footer {
    padding: 10px;
  }

  .modal-body {
    padding: 10px;
  }
}
