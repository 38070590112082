.step-two-container {
    min-height: 40vh;
    width: 90%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-block: 2rem;
}
  
.question-card {
    margin-bottom: 1.5rem;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    padding: 1.2rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.question-text-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f4f4f4;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.action-row {
    margin-top: 10px;
}

.switch-inline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.required-label {
    margin-right: 10px;
    font-weight: bold;
    color: #333;
}

.switch-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.toggle-yes {
    background-color: #e0f9e6;
    padding: 5px 10px;
    border-radius: 5px;
}

.toggle-no {
    background-color: #f4f4f4;
}

.toggle-switch {
    width: 34px;
    height: 20px;
    appearance: none;
    background-color: #ccc;
    outline: none;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s;
}

.toggle-switch:checked {
    background-color: #28a745;
}

.toggle-switch:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
}

.toggle-switch:checked:before {
    transform: translateX(14px);
}

.delete-btn-container {
    text-align: right;
    /* display: flex; */
    justify-content: flex-end;
    align-items: center;
}

.delete-question-btn {
    background-color: #dc3545;
    color: white;
    padding: 8px 16px; /* Adjust padding to make the button more compact */
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 250ms ease;
    display: inline-flex;
    align-items: center;
    font-size: 0.9rem;
}

.delete-question-btn:hover {
    background-color: #c82333;
}

/* .delete-icon-inline {
    margin-right: 5px; 
} */


.add-question-area {
    text-align: right;
    margin-top: 1rem;
}

.add-question-btn {
    background-color: #049046;
    color: white;
    padding: 10px 20px;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 250ms ease;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.add-question-btn:hover {
    background-color: #155d45;
}
