.policy-duration-container {
    width: 20rem; /* 320px, matching CountDisplay */
    margin: auto;
    padding: 1rem;
}
  
.policy-duration-title {
    color: rgba(0,0,0,0.5);
    margin-bottom: 1rem;
    text-align: center;
}
  
.duration-items-container {
    display: flex;
    justify-content: space-between;
}

.duration-value {
    font-size: 1.5rem;
    font-weight: 500;
    color: #15803d;
    text-align: center;
}

/* TODO: shift to common css and remove from here and count-display.css */
.divider { 
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    align-self: stretch;
    margin: 0 1rem;
}