.policyLandscaping {
    width: 100%;
    background: white;
    color: black;
    padding: 10px;
}

.flexR {
    display: flex;
    flex-direction: row;
}

.flexRCcenterY {
    align-items: center;
}

.flexRCcenterX {
    justify-content: center;
}



.flexG{
    flex:1;
}

.flexC{
    display: flex;
    flex-direction: column;
}

.flexGap{
    gap: 10px;
}

.flexGapSm{
    gap: 3px;
}

.dividerT{
    border-top: 2px dashed #B9C391;
}

.policyBold{
    font-weight: bold;
}


.textCenter{
    text-align: center;
}

.policyHeading{
    font-size: 20px;
}

.tableScroll{
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 57vh;
    border: 1px solid #B9C391;
}


.policyTable{
    width: 100%;
    border-collapse: collapse;
}

.policyTrackingTable{
    width: 100%;
    border-collapse: collapse;
}

.policyTrackingTable .visualCell{
    padding:3px 2px
}

.policyTrackingTable th, .policyTrackingTable td {
    padding: 1em;
   
}

.policyTrackingTable tfoot,
.policyTrackingTable tfoot th,
.policyTrackingTable tfoot td {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    bottom: -1px;
    background: #fff; /* set background color for the overcome transparent header cell */
    z-index: 4;
    box-shadow: 0 0 0 1px #B9C391;
    
}

.policyTrackingTable .policyStageMilestonesRCell{
    border-left: 0px;
    padding: 0px !important;
}

.policyTrackingTable .pSt{
    padding: 3px 2px;
}

.policyTable th, .policyTable td {
    
    border: 2px solid #B9C391;
}

/* alternate cell background color */

.policyTable tr:nth-child(even) {
    background-color: #F0F4E3;
  }

.policyTable thead,
.policyTable thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 4;
    background: #fff;
    box-shadow: 0 0 0 1px #B9C391;
    font-weight: 600;
}

.policyTable tfoot,
.policyTable tfoot th,
.policyTable tfoot td {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    bottom: -1px;
    background: #fff; /* set background color for the overcome transparent header cell */
    z-index: 4;
    box-shadow: 0 0 0 1px #B9C391;
    
}

.borderX{
    border-left: 1px solid #B9C391;
}

.countryCell{
    width: 10%;
    padding: 10px;
}

.targetCell{
    color: red;
    width: 2%;
    text-align: center;
}

.policyStageCell{
    width: 14%;
    font-weight: bold;
    padding: 10px;
}

.policyTableCell{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}

.policyNameCell{

}

.policyNameCell p{
    font-size: 14px;
    padding:0px 5px;
}

.policyProgressIndicator{
    width: 100%;
    height: 30px;
}

.policyProgressIndicatorBar{
    border-top: 2px dashed red;
    position:absolute;
    left: 0;
    right: 0;
    top:45%
}

.policyStartEndMarker{
    /* diamond shape */
    width: 10px;
    height: 10px;
    background-color: red;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 32%;
    transform: rotate(45deg);
}

.policyProgressIndicatorMarker{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: dodgerblue;
    position: absolute;
    top: 32%;
}

.policyStartEndMarkerEnd{
    right: 0;
}

.milestoneIndicator{
    background-color: dodgerblue;
    flex:1;
    min-height: 8px;
    border-radius: 5px;
}


.policyVerticalLine{
    width: 2px;
    position: absolute;
    bottom: 0;
    left:10;
    background: dodgerblue;
}
