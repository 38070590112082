.custom-modal {
    max-width: 600px;
    margin: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .modal-header {
    background-color: #137A08;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
  }

  .modal-title {
    font-size: 24px;
    margin: 0;
    color: black;
  }

  .modal-subtext {
    text-align: left;
    margin-top: 4px;
    margin-bottom: 4px;
    color: #555;
    font-size: 12px;
    font-style: italic;
  }

  .modal-body {
    padding: 20px;
  }

  .form-label {
    font-weight: bold;
    color: #036F36;
    margin-bottom: 3px;
    display: block;
  }

  .input-field {
    width: 100%;
    padding: 8px;
    margin-top: 3px;
    margin-bottom: 5px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #F3F4F6;
  }

  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
    background-color: #f2f2f2;
    list-style: none;
    border-radius: 8px;
  }

  .suggestion-item {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .suggestion-item:hover {
    background-color: #e0e0e0;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    border-top: none;
  }

  .btn-primary {
    background-color: #036F36;
    color: #FFFDB8;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }

  .btn-primary:hover {
    background-color: #028A31;
  }

  .required {
    color: red;
  }

