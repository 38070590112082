.bg-v-light-green {
  background: #f2ffe5;
}
.bg-v-light-purple {
  background: #f6effd;
}
.bg-v-light-orange {
  background: #fef1e4;
}
.bg-v-light-grey {
  background: #f8f9fb;
}
.bg-v-light-red {
  background: #f5cbcc;
}
.bg-white {
  background: #fff;
}
.bg-btn-light-green {
  background: #d0eae7;
}
.bg-btn-light-purple {
  background: #dddefb;
}
.bg-light-orange {
  background: #ef7a00;
}
.bg-light-green {
  background: #5ed100;
}
.bg-light-purple {
  background: #9700e5;
}
.bg-dark-green {
  background: #24420e;
}
.bg-v-light-sky-blue {
  background: #e4f5ff;
}
.bg-light-sky-blue {
  background: #0c92f7;
}
.color-red {
  color: #f00;
}
// .bg-login {
//   background: #cde381 !important;
// }
.carousel {
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.carousel-inner {
  display: flex;
  width: calc(200%);
  /* Duplicating images */
  animation: scroll-left 20s linear infinite;
  /* Continuous scroll */
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}


@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
.bg-auth{
  background-image: url("../images/auth-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: fixed;
  width:100%;
  height: 100vh;
  top:0;
  z-index: -1;
}
.bg-auth-img {
  background-image: url("../images/auth-1.svg");
  background-repeat: no-repeat;
  
}
.bg-auth-circles {
  background-image: url("../images/auth-2.svg");
  background-size: cover;
}

.bg-signup-img {
  background: url("../images/signup-people.png");
  background-size: cover;
}
.bg-user-background {
  background: url("../images/user-background.png");
  background-size: auto;
  background-size: 100%;
}
.bg-signup {
  background: linear-gradient(to right, #fff 50%, #cde381 50%) !important;
}
.font-white {
  color: #fff;
}
.font-black {
  color: #000;
}

.pt-7 {
  padding-top: 4rem !important;
}
.pb-7 {
  padding-bottom: 4rem !important;
}
.pt-10 {
  padding-top: 8rem !important;
}
.pb-10 {
  padding-bottom: 8rem !important;
}
.border-grey-1 {
  border: 1px solid #e2dddd;
}

body {
  background-color: transparent;
  color: #000000;
  // overflow-y: hidden;
  // overflow-x: hidden;
  height: auto;
  overflow: auto scroll !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px; 
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 3px;
  background: #dee1e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8F8F8F;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}
/*scroll*/

// p{
//     margin-bottom: 0;
// }
// //could affect other modules

.table th {
  background-color: #f3f4f6;
}

.page-header {
  padding-left: 15px;
  font-weight: 600;
  font-size: 1.1rem;
  padding-top: 15px;
}

.hover-event {
  cursor: pointer;
}

.page-body {
  background: white;
  margin: 100px 30px;
  border-radius: 6px;
  //box-shadow: rgb(23 26 31 / 0%) 0px 0px 1px, rgb(23 26 31 / 42%) 0px 0px 2px
}

.common-button { //TODO: Replace Reactstrap Button with html button wherever it is using common-button or common-outlined-button classname, and remove margin from icons inside it.
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(3, 111, 54);
  color: #FFFDB8;
  margin: 15px 10px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 5px;
  padding: 6px 12px;
  border: none;
  gap: 0 4px;
  cursor: pointer;
}

.common-button:hover{
  background-color: rgba(3,111,54,0.9);
}

.common-outlined-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #036F36;
  margin: 15px 10px;
  font-size: 14px;
  line-height: 22px;
  padding: 6px 12px;
  border: 1px solid #036F36;
  border-radius: 4px;
  gap: 0 4px;
}

.common-outlined-button:hover{
  color: #000;
  border: 1px solid #000;
}

.common-cancel-button {
  color: #EAE200;
  background: white;
  border: 1px solid #EAE200;
  margin: 15px 10px;
  font-size: 14px;
  line-height: 22px;
}

//TODO: edit the secondary button 

.delete-button {
  display: flex;
  align-items: center;
  margin: 15px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFDB8;
  background-color: red;
  border-radius: 4px;
  padding: 6px 12px;
  gap: 0 4px;
  cursor: pointer;
}

.delete-button:hover{
  background-color: rgba(255,0,0,0.9);
}

.table {
  --bs-table-striped-bg: #ffffff;
  --bs-table-bg: #ffffff;
  font-size: 14px;
  table-layout: fixed;
}

tr td.fix-col,
tr td.fix-col-1 {
  background-color: #f8f8f8;
}

.light-icon {
  color: gray;
}
.bg-progress-red-overlay {
  background: #d75c5c;
}
.bg-progress-red {
  background: #f5cbcc;
}
.bg-progress-green-overlay {
  background: #01a824;
}
.bg-progress-green {
  background: #d3ffe3;
}
.bg-progress-blue-overlay {
  background: #4069e5;
}
.bg-progress-blue {
  background: #c4d0f8;
}
.progress-green {
  color: #01a824;
}
.progress-blue {
  color: #4069e5;
}
.progress-red {
  color: #d75c5c;
}

.font-green {
  color: #01a824;
}
.font-white {
  color: #fff;
}
.color-red {
  color: #d75c5c;
}
.color-light-sky-blue {
  color: #30b6cb;
}
.color-light-green {
  color: #3dd36e;
}
.color-light-orange {
  color: #d98f5f;
}
.color-light-purple {
  color: #8867d0;
}
.color-light-blue {
  color: #586bc6;
}
.form-control {
  border-color: hsl(0, 0%, 80%);
}
//to make border color same as react select

.tablet {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 25px;
  height: 30px;
  font-size: 14px;
}
//remove if not used

.color-contrast-tab {
  width: fit-content;
  border-radius: 25px;
  color: var(--tabcolor);
  height: max-content;
  background-color: var(--bgcolor);
  filter: brightness(85%);
  padding: 2%;
}

.dropdown-display-tabs {
  background-color: lightgray;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
}

.dropdown-display-tabs:hover {
  cursor: pointer;
  background-color: gray;
  color: white;
}

.link-hover {
  color: #495057;
}

.link-hover:hover {
  color: #1091f4ff;
  text-decoration: underline;
}

.form-link:hover {
  color: #1091f4ff;
  text-decoration: underline;
  cursor: pointer;
}

.button-disabled:hover {
  cursor: not-allowed;
}

.invalid-input {
  color: #f46a6a;
  font-size: 0.9em;
}

svg > rect:first-child {
  fill-opacity: 1 !important;
}

.accordion-body {
  padding: 0;
}
.select-light-sky-blue {
  border-bottom: 1px solid #30b6cb;
}
.select-light-green {
  border-bottom: 1px solid #3dd36e;
}
.select-light-orange {
  border-bottom: 1px solid #d98f5f;
}
.select-light-purple {
  border: 1px solid #8867d0;
}
.select-light-blue {
  border: 1px solid #586bc6;
}

.pagination {
  .page-item {
    &:first-child {
      .page-link {
        margin-right: 0; //rtl
        // border-top-left-radius: 0px;
        // border-bottom-left-radius: 0px;
        font-size: 12px;
        border-radius: 50%;
        // @include border-right-radius($border-radius); //rtl
      }
    }
    &:last-child {
      .page-link {
        // border-top-right-radius: 0px;
        // border-bottom-right-radius: 0px;
        font-size: 12px;
        border-radius: 50%;
        // @include border-left-radius($border-radius); //rtl
      }
    }
  }
}

.page-item .page-link {
  font-size: 12px;
  border-radius: 50%;
  width: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
}

.page-item {
  margin: 2px;
}

.pagination-bar {
  background-color: white;
  margin-top: 20px;
}

#sidebar-menu {
  position: fixed;
}

.country-flag {
  border-radius: 12px;
  border: 2px solid #ffffff;
}

.user-img {
  border-radius: 18px;
  border: 1.5px solid #ffffff;
}

.main-content {
  overflow: visible;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #ffe9e5ff;
}

.accordion-button {
  color: black;
  background-color: #ffe9e5ff;
}

// .black-button {
//   background-color: #000000;
//   color: #ffffff;
//   border: none;
// }
.badge-break-word {
  word-break: break-word;
  white-space: normal;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 5px;
}

.new-btn-light:hover {
  cursor: pointer;
  color: #000;
}

.new-btn-dark:hover {
  cursor: pointer;
  color: #fff;
}

.auth-body {
 overflow: hidden;
}
