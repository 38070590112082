.container {
    position: relative;
    display: flex;
    flex:1
}
  
.hoverTarget {
    cursor: pointer;
    display: flex;
    flex:1;
    min-height: 12px;
}
  
.popup {
    position: absolute;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 10px;
  }
  
  .above {
    bottom: 100%; /* Position the popup above the hover section */
    margin-bottom: 10px; /* Add some space between the hover section and popup */
  }