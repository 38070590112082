.page-content {
    padding: 20px;
    background-color: white;
    min-height: 100vh;
    margin-left: 2rem;
    margin-right: 2rem;
}

.header-title {
    font-size: 24px;
    font-weight: 400;
    color: #198754;
    margin-bottom: 20px;
    margin-left: 2rem; /* Match margin with form content */
    margin-right: 2rem; /* Match margin with form content */
}

.tabs-list {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px 0;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    margin-bottom: 20px; 
    margin-left: 2rem; /* Align with form content */
    margin-right: 2rem; /* Align with form content */
    gap: 15px; /* Add space between the tabs */
}

.tabs-trigger {
    all: unset;
    font-size: 1rem;
    padding: 12px 20px;
    text-align: center;
    flex: 1; /* Makes the tabs fill the space evenly */
    background-color: #f8f9fa;
    color: #1D1D1D;
    transition: all 250ms ease;
    border-radius: 0.5rem;
    cursor: pointer;
}

.tabs-trigger[data-state="active"] {
    background-color: #b7e4c7;
    color: white;
    font-weight: 500;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Increased shadow intensity */
    transform: translateY(-3px); /* Lifted effect for the active tab */
}

.tabs-trigger:hover {
    background-color: #b7e4c7;
    color: white;
}

.tabs-content {
    padding: 20px;
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    margin-left: 2rem; /* Same margin as the rest */
    margin-right: 2rem; /* Same margin as the rest */
}

label {
    font-size: 16px;
    color: #049046;
    font-weight: bold;
}

select {
    padding: 8px;
    font-size: 0.875rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    background-color: #f4f4f4;
}

.add-questions-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-left: 2rem; /* Align with form content */
    margin-right: 2rem; /* Align with form content */
}

.add-questions-btn {
    all: unset;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #198754;
    color: white;
    border-radius: 0.5rem;
    transition: all 250ms ease;
}

.add-questions-btn:hover {
    background-color: #155d45;
}
