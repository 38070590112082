.partners-dropdown {
    position: relative;
  }
  
  .dropdown-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .dropdown-button .dropdown-icon {
    margin-left: 5px;
  }
  
  .dialog-box {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1em;
    border-radius: 8px;
    width: 200px;
  }
  
  .dialog-box ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }
  
  .dialog-box li {
    padding: 0.5em 0;
  }
  
  .close-dialog {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1em;
  }
  