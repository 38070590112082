/* Progress bar */
.progress-bar-container {
  width: 80%;
  margin: 20px auto;
  background-color: #f1f1f1;
  height: 12px;
  border-radius: 8px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #ff4e4e; /* Default red */
  transition: width 0.4s ease, background-color 0.4s ease; /* Smooth transition for width and color */
}

/* Questionnaire container */
.questionnaire-container {
  width: 80%;
  margin: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.questions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-card {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-title {
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}

.answer-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}

.radio-label input[type="radio"] {
  display: none;
}

.radio-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 50%;
  background-color: transparent;
  display: inline-block;
  position: relative;
}

.radio-label input[type="radio"]:checked + .radio-custom {
  background-color: #b7e4c7;
  border-color: #198754;
}

.radio-custom::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #198754;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio-label input[type="radio"]:checked + .radio-custom::after {
  display: block;
}

/* Navigation buttons */
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.prev-button,
.next-button {
  background-color: #198754;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

.prev-button:hover,
.next-button:hover {
  background-color: #155d45;
}
