@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Adjust arrow and other icons */
.arrow-icon {
  display: inline-block;
  margin-right: 0.5em;
  background-color: #FFFDB8;
  padding: 2px;
  border-radius: 10px;
}

/* Status labels for scrollable table */
.status-label {
  display: inline-block;
  padding: 0.3em 0.6em;
  border-radius: 0.25em;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-right: 0.3em; /* Small space between status labels */
  margin-bottom: 0.3em; /* Additional margin to separate rows */
}

/* Adjust dropdown styling for wrapping */
.partners-dropdown-container {
  display: block; /* Ensure block display to wrap properly */
  margin-top: 0.5em;
}

.partners-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.partners-dropdown ul li {
  display: block; /* Ensure each partner item is on its own line */
  padding: 0.3em 0;
  font-size: 12px;
}

/* Style for the action icons in the last column */
.last-column {
  text-align: right;
}

.col-w-1 {
  width: 12em;
  overflow-wrap: break-word;
}

.col-w-2 {
  width: 8em;
}

.col-w-3 {
  width: 4.5em;
}

.col-w-4 {
  width: 9em;
}

.col-w-5 {
  width: 17em;
}

/* th {
    text-align: center;
}

td{
    text-align: center;
} */

.col-w-lg {
  width: 16em;
}

.col-w-md {
  width: 12em;
}
.col-w-sm {
  width: 8em;
}
.col-w-xs {
  width: 4em;
}

.col-w-6 {
  width: 7em;
}

.col-w-7 {
  width: 8em;
}

.fix-col {
  /* background: #ffffff; */
  z-index: 1;
  position: sticky;
  left: 0;
}

.fix-col-1 {
  position: sticky;
  left: 16em;
  z-index: 1;
}

.fix-col-1::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-right: 3px solid white;
}