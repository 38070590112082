.step-three-container {
    width: 85%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .question-card-preview {
    padding: 1.5rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .question-text-preview {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 500;
    padding: 0;
  }
  
  .edit-question-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .required-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .required-label {
    font-weight: bold;
    color: #333;
  }
  
  .switch-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .toggle-switch {
    width: 34px;
    height: 20px;
    appearance: none;
    background-color: #ccc;
    outline: none;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .toggle-switch:checked {
    background-color: #28a745;
  }
  
  .toggle-switch:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
  }
  
  .toggle-switch:checked:before {
    transform: translateX(14px);
  }
  
  .button-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  
  .delete-question-btn, .edit-question-btn {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 8px 14px;
    border-radius: 0.5rem;
  }
  
  .delete-icon-inline, .edit-icon-inline {
    margin-right: 8px;
  }
  
  .save-submit-container {
    margin-top: 2rem;
    text-align: center;
  }
  
  .save-submit-btn {
    padding: 15px 30px;
    background-color: #198754;
    border-radius: 12px;
    border: none;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
  
  .save-submit-btn:hover {
    background-color: #155d45;
  }
  
  .save-submit-btn i {
    font-size: 1.3rem;
    vertical-align: middle;
  }
  