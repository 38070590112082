
.stages_explainer{

}

.stages_explainer h5{
    color: #4D4D4D;
    margin: 5px 0px;
    padding : 10px 0px;
    border-bottom: 1px solid #4D4D4D;
    font-weight: bold;
}

.stages_explainer ol{
    list-style: decimal !important;
}