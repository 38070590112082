/* Adjust padding between tabs, filters, and tables */
.card-header {
    padding: 8px 16px !important;  /* Reduce padding */
  }
  
  .d-flex.justify-content-between {
    margin-bottom: 5px; /* Reduce spacing between filters and content */
  }
  
  .styled-container {
    margin-bottom: 5px; /* Control spacing between SearchBox and Filters */
  }
  
  .card-body {
    padding: 12px !important; /* Adjust padding inside the table body */
  }
  