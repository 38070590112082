.sub-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 30px;
    height: 2.5rem;
    padding: 10px;
    width: 25%;
    cursor: pointer;
    margin: 0.5rem 0px;
    border: 0.5px solid #D9D9D9;
}

.sub-tab:hover {
    background-color: #FFFDB8;
    color: #036F36;
}

.sub-tab.sub-tab-active {
    background-color: rgb(255, 253, 184);
    color: rgb(3, 111, 54);
}

.main-tab{
    background-color: #F5F5F5;
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
}

.main-tab:hover {
    background-color: #EAE200;
    color: #036F36;
}

.main-tab.main-tab-active{
    background-color: #EAE200;
    color: #036F36;
    border-bottom: 3px solid #036F36;
}

.country-selection{
    background-color: #C8FDE2;
    padding: 10px 36px;
    height: 3.5rem;
    width: fit-content;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.chart-section {
    display: flex;
    align-items: start;
    margin: 1.5rem 0px;
}

.chart-container {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 15px 15px;
    width: fit-content;
}

.chart-legend-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-summary {
    padding: 1rem;

    & .green__accent {
        color: #13a800;
        font-weight: 500;
      }
}

.section-title {
    font-size: 24px;
    margin: 0.5rem 0;
    text-wrap: nowrap;
}

.data-page {
    display: flex;
    justify-content: center;
}

.data-page-content{
    width: 75%;
}

.chart-height{
    height: 220px;
}