@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


/* Styling for the first column data */
/* systems.css */

/* General styling for the entire table container */
.custom-table-container {
    width: 100%;
    padding: 1em;
    overflow-x: auto;
    /* Allows horizontal scroll if necessary */
}

/* General styling for the table */
.custom-table {
    width: 100%;
    table-layout: fixed;
    background-color: transparent;
    border-radius: 16px, 16px, 0px, 0px;
    overflow: hidden;
    font-family: 'Poppins';
}

.custom-table thead {
    background-color: #f8f9fa;
    font-weight: 500;
    font-size: 14px;
}

.custom-table th {
    padding-block: 2em;
    background-color: #e9ecef;
    text-align: left;
    /* font-weight: bold; */
    font-size: 14px;
    color: #F0F0F0;
    border-bottom: 2px solid #dee2e6;
}

.custom-table td {
    padding: 2.5em 2em;
    text-align: left;
    font-size: 0.875rem;
    border-top:  1px solid #dee2e6;
    /* Remove all borders */
    border-bottom: 1px solid #dee2e6;
    border-left: none !important;
    border-right: none !important; 
    /* Only keep the bottom border */
    vertical-align: middle;
    background-color: white;
    /* Ensure the background is white */
}

.custom-table td:first-child {
    border-top: none;
    /* Ensure no top border on the first row */
    color: #036f36;
    font-weight: 500;
    /* Set the text color for the first column */
    /* font-weight: bold; */
    /* Optional: make the first column text bold */
}

.custom-table td:third-child {
    color: #000000;
    font-weight: bold;
}

.custom-table tbody tr {
    background-color: white !important;
    /* Ensure all rows have a white background */
    cursor: default;
}

.custom-table tbody tr:hover {
    background-color: #f8f9fa;
    /* Add a hover effect for rows */
}

.custom-table tbody tr td:first-of-type .name-cell {
    padding: 0.2em 0.4em;
    border-radius: 0.8em;
    background-color: rgba(80, 165, 241, 0.1);
    color: rgba(80, 165, 241);
}

/* Styling for the description cell to handle long text */
.custom-table .description_cell {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    max-width: 200px;
}

.custom-table .popover-trigger {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 200px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
}

/* Last row's cells */
.custom-table tbody tr:last-child td {
    border-bottom: none;
}

/* Styling for the button group */
.custom-table .button-group {
    display: flex;
    gap: 1em;
    border-collapse: separate;
}

.custom-table .button-group button {
    all: unset;
    cursor: pointer;
}

.custom-table .mdi-pencil {
    color: #4caf50;
    /* Green for edit */
    transition: transform 0.2s;
}

.custom-table .mdi-delete {
    color: #f44336;
    /* Red for delete */
    transition: transform 0.2s;
}

.custom-table .button-group button:hover .mdi-pencil,
.custom-table .button-group button:hover .mdi-delete {
    transform: scale(1.1);
}

/* Popover styling */
.custom-table .popover-content {
    background-color: #000;
    color: whitesmoke;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.custom-table .popover-content::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    width: 10px;
    height: 10px;
    background-color: #000;
    transform: rotate(45deg);
    z-index: -1;
}

/* Optional: styling for the arrow icon */
.custom-table .arrow-icon {
    display: inline-block;
    margin-right: 0.5em;
    color: #036f36;
    /* text-shadow: 1 1 5px #FFFDB8; */
    /* box-shadow: 0 0 0 3px #FFFDB8; */
    background-color: #FFFDB8;
    /* Creates a small square highlight around the icon */
    padding: 2px;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    z-index: 1;
}

.table-head {
    color: #4D4D4D;
}
