.responsive-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem 5rem;
    /* padding: 2.5rem 0; */
}
  
@media (min-width: 768px) {
    .responsive-grid {
        grid-template-columns: 1fr 1fr;
    }
}

.milestone-title {
    font-size: 16px;
    margin: 0.5rem 0;
}

.gauge-wrapper{
    position: relative;
    height: 135px;
}

.gauge-centered-text { 
    border-radius: 100%;
    padding: 20px;
    border: 1px solid #c6dac8;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 12px;
    color: #036F36;
}