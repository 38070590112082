@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


/* Styling for the first column data */
/* systems.css */

/* General styling for the entire table container */
.custom-table-container {
  width: 100%;
  padding: 1em;
  overflow-x: auto;
  /* Allows horizontal scroll if necessary */
}

/* General styling for the table */
.custom-table {
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
  border-radius: 16px, 16px, 0px, 0px;
  overflow: hidden;
  font-family: 'poppins', sans-serif;
}

.custom-table thead {
  background-color: #f8f9fa;
  font-weight: 500;
  font-size: 14px;
}

.custom-table th {
  padding-block: 2em;
  background-color: #e9ecef;
  text-align: left;
  /* font-weight: bold; */
  font-size: 13px;
  color: #F0F0F0;
  border-bottom: 2px solid #dee2e6;
}

.custom-table td {
  padding: 2.5em 2em;
  text-align: left;
  font-size: 0.875rem;
  border-top: none;
  /* Remove all borders */
  border-bottom: 1px solid #dee2e6;
  border-left: none !important;
  border-right: none !important;
  /* Only keep the bottom border */
  vertical-align: middle;
  background-color: white;
  /* Ensure the background is white */
}

.custom-table td:first-child {
  border-top: none;
  /* Ensure no top border on the first row */
  color: #036f36;
  /* Set the text color for the first column */
  /* font-weight: bold; */
  /* Optional: make the first column text bold */
}

.custom-table td:third-child {
  color: #000000;
  font-weight: bold;
}

.custom-table tbody tr {
  background-color: white !important;
  /* Ensure all rows have a white background */
  cursor: default;
}

.custom-table tbody tr:hover {
  background-color: #f8f9fa;
  /* Add a hover effect for rows */
}

.custom-table tbody tr td:first-of-type .name-cell {
  padding: 0.2em 0.4em;
  border-radius: 0.8em;
  background-color: transparent;
  /* Remove the blue highlight */
  color: #036f36;
  /* Green text */
}

/* Styling for the description cell to handle long text */
.custom-table .description_cell {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 200px;
}

.custom-table .popover-trigger {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
}

/* Last row's cells */
.custom-table tbody tr:last-child td {
  border-bottom: none;
}

/* Styling for the button group */
.custom-table .button-group {
  display: flex;
  gap: 1em;
  border-collapse: separate;
}

.custom-table .button-group button {
  all: unset;
  cursor: pointer;
}

.custom-table .mdi-pencil {
  color: #4caf50;
  /* Green for edit */
  transition: transform 0.2s;
}

.custom-table .mdi-delete {
  color: #f44336;
  /* Red for delete */
  transition: transform 0.2s;
}

.custom-table .button-group button:hover .mdi-pencil,
.custom-table .button-group button:hover .mdi-delete {
  transform: scale(1.1);
}

/* Popover styling */
.custom-table .popover-content {
  background-color: #000;
  color: whitesmoke;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.custom-table .popover-content::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: #000;
  transform: rotate(45deg);
  z-index: -1;
}

/* Optional: styling for the arrow icon */
.custom-table .arrow-icon {
  display: inline-block;
  margin-right: 0.5em;
  color: #036f36;
  /* text-shadow: 1 1 5px #FFFDB8; */
  /* box-shadow: 0 0 0 3px #FFFDB8; */
  background-color: #FFFDB8;
  /* Creates a small square highlight around the icon */
  padding: 2px;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.name-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  color: #036f36;
  cursor: pointer;
  padding: 0;
  font-size: 0.875rem;
}

.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  padding: 0.5em;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  min-width: 200px;
  white-space: normal;
  word-wrap: break-word;
}



.filter-dropdown {
  /* Adjust your filter dropdown styles */
  width: 100%;
}

.filter-dropdown-menu {
  width: 300px;
  /* Adjust the width to match your design */
  padding: 10px;
}

.filter-dropdown .common-button {
  background-color: #00a551;
  /* Adjust color */
  border: none;
}

.basic-multi-select {
  width: 100%;
}


/* Side drop down */
/* Partners.css */
.policy-directory-dropdown {
  position: relative;
}

.sidebar-dropdown {
  position: absolute;
  top: 100%;
  /* Adjust to align with the dropdown */
  left: 100%;
  /* Position to the right of the main dropdown */
  transform: translateX(10px);
  /* Space between the dropdowns */
  z-index: 1050;
  /* Ensure it appears above other content */
  background-color: white;
  /* Background color of the sidebar dropdown */
  border: 1px solid #ddd;
  /* Border styling */
  padding: 10px;
  width: 250px;
  /* Adjust width as needed */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  /* Optional box shadow */
}

.close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.search-bar {
  margin-bottom: 10px;
}

.sidebar-dropdown ul {
  list-style: none;
  padding: 0;
}

.sidebar-dropdown li {
  padding: 5px 0;
}


/* Partners.css */
.policy-directory-dropdown .dropdown-menu {
  border-radius: 10px;
  /* Add rounded borders */
  background-color: white;
  /* Ensure the dropdown background is white */
  border: 1px solid #eaeaea;
  /* Light border for the dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
}

.policy-directory-dropdown .dropdown-item {
  color: #4D4D4D;
  /* Change text color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  /* Add padding for better spacing */
  border-radius: 8px;
  /* Round each dropdown item */
  transition: background-color 0.2s ease;
  /* Smooth hover effect */
}

.policy-directory-dropdown .dropdown-item:hover {
  background-color: #FFF71F;
  /* Hover color */
  color: #4D4D4D;
  /* Ensure the text color remains the same */
}

.policy-directory-dropdown .mdi-chevron-right {
  color: #036F36;
  /* Change arrow icon color */
}

.policy-directory-dropdown .dropdown-toggle {
  background-color: #036F36;
  /* Add background color to the button */
  color: white;
  /* White text for the button */
  border-radius: 8px;
  /* Round the dropdown toggle button */
}

.policy-directory-dropdown .dropdown-toggle:focus {
  box-shadow: none;
  /* Remove default focus outline */
}


.dropdown-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFDB8;
  /* Highlight color */
  padding: 4px;
  /* Space around the icon to create the highlight effect */
  border-radius: 2px;
  /* Optional: small rounding on the corners for a subtle effect */
}

.dropdown-icon i {
  color: #5ed100;
  /* Green color for the icon */
  font-size: 16px;
  /* Adjust icon size as needed */
}

.custom-modal {
  max-width: 600px;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-header {
  background-color: #137A08;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 15px !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: left !important; /* Ensure the alignment */
  display: flex !important; /* Flexbox ensures consistent layout */
  align-items: center !important; /* Vertical alignment */
  justify-content: flex-start !important; /* Horizontal alignment to the left */
}



.modal-subtext {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
  color: #555;
  font-size: 12px;
  font-style: italic;
}

.modal-body {
  padding: 20px;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #036F36;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #F3F4F6;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: none;
}

.btn-primary {
  background-color: #036F36;
  color: #FFFDB8;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #028A31;
}

.btn-secondary {
  background-color: #c4c4c4;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-label {
  margin-left: 10px;
}
