.policyTrackingTableCell{
    display: flex;
    min-height: 20px;
    background: orange;
    position: relative;
}

.policyProgressIndicatorOver{
    top: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background-color: #44482f;
    position: absolute;
}