.expandablePanel {
    border: 1px solid #ccc;
    margin: 10px;
    border-radius: 4px;
    overflow: hidden; /* Ensures the content is hidden during collapse */
  }
  
  .panelHeader {
    padding: 10px;
    /* background-color: #f4f4f4; */
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .panelHeader h3 {
    margin: 0;
  }
  
  .panelContent {
    padding: 10px;
    background-color: #fafafa;
    max-height: 0; /* Start with no height */
    opacity: 0;    /* Make content invisible */
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out; /* Smooth transition for height and opacity */
  }
  
  .panelContent.expanded {
    max-height: 500px; /* Adjust based on your content's size */
    opacity: 1; /* Make content visible */
  }
  