.custom-modal {
    max-width: 600px;
    margin: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .modal-header {
    background-color: #137A08;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 10px; 
    /* border-top-left-radius: 12px;
    border-top-right-radius: 12px;  */
    text-align: left;
  }
  
  .modal-title {
    font-size: 32px;
    margin-bottom: 0;
    color: black;
    text-align: left;
    margin-left: -10px; 
  }
  
  .modal-body {
    padding-bottom: 5px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #F3F4F6;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    /* padding-top: 10px; */
    border-top: none;
  }
  
  .modal-footer button {
    width: auto; 
    max-width: 48%; 
  }

  .btn-primary {
    background-color: #036F36;
    color: #FFFDB8;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #028A31;
  }

  .modal-subtext {
    text-align: left;
    margin-top: 4px;
    margin-bottom: 4px;
    color: #555;
    font-size: 12px;
    font-style: italic;
  }

  .required {
    color: red;
  }