/* Sidebar base styles */
.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 450px;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1100;
    transition: transform 0.3s ease;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Ensure scrolling is handled inside the content */
}
  
.sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
    font-size: 18px;
    font-weight: 700;
    color: #5c5c5c;
}
  
.close-icon {
    background: none;
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #036f36;
    font-size: 14px;
    font-weight: bold;
    color: #036f36;
    transition: background-color 0.3s ease;
}
  
.close-icon:hover {
    background-color: #f0f0f0;
}
  
.sidebar-tabs {
    display: flex;
    justify-content: space-between;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 20px; /* Add margin to separate tabs from container */
}
  
.tab-button {
    flex: 1;
    padding: 10px 15px;
    text-align: center;
    background-color: white;
    border: none;
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
    transition: background-color 0.3s ease;
    border-radius: 10px 0 0 0;
    height: 40px;
    /* line-height: 50px; */
    white-space: nowrap;
}
  
.tab-button.active {
    background-color: #fffdb8;
    color: black;
}
  
.tab-button:hover:not(.active) {
    background-color: #f0f0f0;
}
  
.sidebar-content {
    padding: 20px;
    overflow-y: auto; /* Ensures the content inside the sidebar scrolls */
    flex-grow: 1;
    max-height: calc(100vh - 80px);
    padding-bottom: 50px; /* Add padding at the bottom to ensure space below the last item */
}

/* Custom scrollbar styling */
.sidebar-content::-webkit-scrollbar {
    width: 8px; /* Customize scrollbar width */
}

.sidebar-content::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Customize the scrollbar color */
    border-radius: 4px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
    background-color: #a1a1a1; /* Darken scrollbar on hover */
}

.tab-content {
    margin-top: 10px;
}
  
h3 {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 15px;
    color: #000000;
}
  
p,
ul {
    font-size: 14px;
    line-height: 1.5;
}
  
ul {
    padding-left: 20px;
}
  
ul li {
    margin-bottom: 8px;
}

/* Style for each bullet point */
.bullet-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
/* Styling for meta info container */
.meta-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #C2C2C2;
}
  
.user-timestamp {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #000000;
}
  
.user-name {
    font-weight: 600;
    font-size: 14px;
}
  
.timestamp {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
}
  
.action-buttons {
    display: flex;
    gap: 8px;
}
  
.edit-button, .delete-button {
    padding: 6px 12px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
}
  
.edit-button {
    background-color: #2e7d32;
    color: white;
    border: none;
}
  
.delete-button {
    background-color: transparent;
    color: #2e7d32;
    border: 1px solid #2e7d32;
}
  
.edit-button:hover, .delete-button:hover {
    opacity: 0.85;
}
  
.sidebar-content ul {
    margin-bottom: 20px;
}

.user-info {
    display: flex;
    align-items: center;
}

.profile-photo {
    width: 40px; /* Adjust size as needed */
    height: 40px;
    border-radius: 50%; /* Circular image */
    margin-right: 10px; /* Space between the photo and text */
    border: 1px solid #137A08; /* Green border */
}

.name-timestamp {
    display: flex;
    flex-direction: column;
}

.user-timestamp {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #000000;
}

.custom-modal {
    max-width: 600px;
    margin: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .modal-header {
    background-color: #137A08;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .form-label {
    font-weight: bold;
    color: #036F36;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #F3F4F6;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: none;
  }
  
  .btn-primary {
    background-color: #036F36;
    color: #FFFDB8;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #028A31;
  }
  
  .btn-secondary {
    background-color: #c4c4c4;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
  }

  .required {
    color: red;
  }
  