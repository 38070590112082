/* DonutChart.css */
  
.donut-chart-wrapper {
    position: relative;
}
  
.centered-text { 
    /* TODO: Fix circular */
    border-radius: 100%;
    padding: 25px;
    border: 1px solid #c6dac8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 12px;
    color: #036F36;
}
  
.custom-legend {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.legend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 16px;
    text-wrap: nowrap;
}
  
.legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
  
.legend-value {
    margin-left: auto;
}